<template>
  <div class="sent">
    <div class="row justify-content-center">
      <div class="col-6">
        <h1 class="text-center text-success">
          <font-awesome-icon icon="check-circle" /> ส่งข้อมูลเรียบร้อยแล้ว
        </h1>
        <div class="w-100 mb-5"></div>
        <div class="text-center">
          <button class="btn btn-warning" @click="redirect">
            <font-awesome-icon icon="undo" /> ส่งข้อมูลเพิ่มเติม
          </button>
        </div>
        <div class="w-100 mb-2"></div>
        <a class="btn btn-primary" :href="'/preview/' + param" target="_blank">
          <font-awesome-icon icon="eye" title="ดูตัวอย่าง" /></a
        >&nbsp;
        <a class="btn btn-warning" :href="'/download/' + param" target="_blank">
          <font-awesome-icon icon="download" title="ดาวน์โหลด" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sent",
  props: ["url", "param"],
  methods: {
    redirect: function () {
      window.location.href = this.url;
    },
  },
};
</script>

<style>
</style>