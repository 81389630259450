<template>
  <div class="home mb-5">
    <Navbar />
    <div>
      <section class="pt-5 mb-5">
        <div class="container">
          <HeadSection txt="คำร้องขอใบระเบียนแสดงผลการเรียน (รบ.1/ปพ.1)" />
          <form
            @change="updateMessage"
            @submit.prevent="pushMessage"
            v-show="!status"
          >
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="row">
                  <div class="col mb-4">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        v-model="form.type"
                        value="ปพ.1"
                      />
                      <label class="form-check-label" for="inlineRadio1"
                        >ปพ.1</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio2"
                        v-model="form.type"
                        value="ปพ.1 5 ภาคเรียน"
                      />
                      <label class="form-check-label" for="inlineRadio2"
                        >ปพ.1 5 ภาคเรียน</label
                      >
                    </div>
                  </div>
                  <div class="w-100"></div>
                  <div class="col-md-2 mb-2">
                    <div class="form-floating">
                      <select
                        class="form-select"
                        id="floatingSelect"
                        v-model="form.prename"
                      >
                        <option v-for="i in pre" :key="i" :value="i">
                          {{ i }}
                        </option>
                      </select>
                      <label for="floatingSelect">คำนำหน้า</label>
                    </div>
                  </div>
                  <div class="col-md-5 mb-2">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingName"
                        v-model="form.fname"
                        required
                      />
                      <label for="floatingName">ชื่อ</label>
                    </div>
                  </div>
                  <div class="col-md-5 mb-2">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingLastname"
                        v-model="form.lname"
                        required
                      />
                      <label for="floatingLastname">นามสกุล</label>
                    </div>
                  </div>
                  <div class="w-100"></div>
                  <div class="col-md-4 mb-2">
                    <div class="form-floating">
                      <input
                        type="number"
                        class="form-control"
                        id="floatingID"
                        v-model="form.std_id"
                        required
                      />
                      <label for="floatingID">เลขประจำตัวประชาชน</label>
                    </div>
                  </div>
                  <div class="col-md-2 mb-2">
                    <div class="form-floating">
                      <input
                        type="number"
                        class="form-control"
                        id="floatingLevel"
                        v-model="form.level"
                        min="1"
                        max="6"
                        required
                      />
                      <label for="floatingLevel">ชั้นมัธยมศึกษาปีที่</label>
                    </div>
                  </div>
                  <div class="col-md-2 mb-2">
                    <div class="form-floating">
                      <input
                        type="number"
                        class="form-control"
                        id="floatingSubLevel"
                        v-model="form.sub_level"
                        min="1"
                        max="20"
                        required
                      />
                      <label for="floatingSubLevel">ห้อง</label>
                    </div>
                  </div>
                  <div class="col-md-4 mb-2">
                    <div class="form-floating">
                      <input
                        type="number"
                        class="form-control"
                        id="floatingSemester"
                        v-model="form.semester"
                        min="1"
                        required
                      />
                      <label for="floatingSemester">ปีการศึกษา</label>
                    </div>
                  </div>
                  <div class="col-md-4 mb-2">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-floating">
                          <select
                            class="form-select"
                            id="floatingBirthday"
                            v-model="bday"
                            required
                          >
                            <option v-for="i in 31" :key="i" :value="i">
                              {{ i }}
                            </option>
                          </select>
                          <label for="floatingBirthday">วัน</label>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="form-floating">
                          <select
                            class="form-select"
                            id="floatingMonth"
                            v-model="bmonth"
                            required
                          >
                            <option
                              v-for="(value, index) in month"
                              :key="index"
                              :value="index + 1"
                            >
                              {{ value }}
                            </option>
                          </select>
                          <label for="floatingMonth">เดือนเกิด</label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-floating">
                          <select
                            class="form-select"
                            id="floatingYear"
                            v-model="byear"
                            required
                          >
                            <option v-for="i in 50" :key="i" :value="2564 - i">
                              {{ 2564 - i }}
                            </option>
                          </select>
                          <label for="floatingYear">ปีเกิด</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100"></div>
                  <div class="col-md-6 mb-2">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingFatherName"
                        v-model="form.father_name"
                        required
                      />
                      <label for="floatingFatherName">ชื่อบิดา</label>
                    </div>
                  </div>
                  <div class="col-md-6 mb-2">
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingMotherName"
                        v-model="form.mother_name"
                        required
                      />
                      <label for="floatingMotherName">ชื่อมารดา</label>
                    </div>
                  </div>
                  <div class="w-100"></div>
                  <div class="col-md-12 mb-2">
                    <div class="form-floating">
                      <textarea
                        class="form-control"
                        id="floatingPurpose"
                        v-model="form.purpose"
                        required
                      ></textarea>
                      <label for="floatingPurpose">วัตถุประสงค์ในการขอ</label>
                    </div>
                  </div>
                  <div class="w-100"></div>
                  <div class="col text-start">
                    <button
                      type="submit"
                      name="submit_btn"
                      class="btn btn-primary"
                    >
                      ส่งข้อมูล
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <Sent url="register1" :param="param" v-show="status" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import HeadSection from "@/components/HeadSection.vue";
import Sent from "@/components/Sent.vue";
import firebase from "../db.js";
import "firebase/database";
const dbRef = firebase.database().ref("documents");

export default {
  name: "Register1",
  components: {
    Navbar,
    HeadSection,
    Sent,
  },
  data() {
    return {
      form: {
        type: "ปพ.1",
        main_type: "ปพ",
        doc_name: "คำร้องขอใบระเบียนแสดงผลการเรียน (รบ.1/ปพ.1)",
        fname: "",
        lname: "",
        prename: "เด็กชาย",
        name: "",
        std_id: "",
        level: "",
        sub_level: "",
        birthday: null,
        mother_name: "",
        father_name: "",
        purpose: "",
        timestamp: new Date().toUTCString(),
        semester: "",
        status: "PENDING",
      },
      // dbRef: db.database().ref("documents"),
      month: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      bday: null,
      bmonth: null,
      byear: null,
      status: false,
      pre: ["เด็กชาย", "เด็กหญิง", "นาย", "นาง", "นางสาว"],
      param: "",
    };
  },
  methods: {
    updateMessage() {
      this.form.name = this.name;
      this.form.birthday = new Date(this.calculate_birthday).toUTCString();
      this.$store.state.form = this.form;
    },
    async pushMessage(e) {
      e.submitter.innerText = "กรุณารอซักครู่";
      e.submitter.disabled = true;

      let last = 0;

      await dbRef
        .get()
        .then((snapshot) => {
          if (snapshot.exists()) {
            last = snapshot.numChildren();
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });

      let count = 0;
      if (last > 0) {
        count = last;
      }
      await dbRef
        .child(count)
        .set(this.form)
        .then(() => {
          this.status = true;
          this.param = count;
        })
        .catch(() => {
          alert("ไม่สามารถส่งข้อมูลได้");
        });
    },
  },
  computed: {
    name: function () {
      let txt = this.form.prename + this.form.fname + "  " + this.form.lname;
      return txt;
    },
    calculate_birthday: function () {
      let date = this.byear - 543;
      date = date + "-" + this.bmonth + "-" + this.bday;
      return date;
    },
  },
};
</script>


<style>
</style>